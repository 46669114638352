<template>
    <div class="p-2">
        <b-breadcrumb class="mb-2" :items="breadcrumbs"></b-breadcrumb>
        <div class="row no-gutters">
            <div class="col-3 pr-2">
                <b-card>
                    <filters-loader endpoint="organisation-log-filters"
                                    v-on:filters_updated="(filters) => { filtersUpdated(filters)}"/>
                </b-card>
            </div>
            <div class="col-9">
                <paginated-result-loader endpoint="organisation-logs" cell-type="organisation_logs"
                                         :filters="request"></paginated-result-loader>
            </div>

        </div>
    </div>
</template>

<script>
    import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
    import FiltersLoader from "../../../components/FiltersLoader";

    export default {
        name: "config_log",
        components: {FiltersLoader, PaginatedResultLoader},
        data: function () {
            return {
                request: {},
                paginatedResponse: {},
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Organisation config log',
                        active: true
                    }
                ]
            }
        },
        methods : {
            filtersUpdated(filters) {
                this.request = filters;
            }
        }

    }
</script>